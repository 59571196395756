import React, { useState, useEffect } from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import { getBlocksData, getCategoriesBySlug, getCirclesData } from '../api'
import noimage from '../../img/No-Photo-Available.jpg'
import { Link, useLocation } from 'react-router-dom'
import HeroImg from '../../img/article.jpg'
import { Helmet } from 'react-helmet'
import { NotFound } from '../404Error'
import { useData } from '../../contexts/Datacontext'
import queryString from 'query-string'
import { Select, Spin } from 'antd'
import TextTruncate from 'react-text-truncate'
import useDeviceWidth from '../device-width/UseDeviceWidth'

const Category = (props) => {
  const { Option } = Select

  const [page, setPage] = useState({})
  const [outlets, setOutlets] = useState([])
  const [circles, setCircles] = useState([])
  const [selectedCircleId, setSelectedCircleId] = useState('')
  const [selectedCircle, setSelectedCircle] = useState('')
  const [loading, setLoading] = useState(true);
  const [blocks, setBlocks] = useState([])
  const [selectedBlockId, setSelectedBlockId] = useState('')
  const [selectedBlock, setSelectedBlock] = useState('')
  const [id, setId] = useState('')
  const [noData, setNoData] = useState(false)
  const { data } = useData();
  const width = useDeviceWidth();

  const user = localStorage.getItem("user")

  useEffect(() => {
    window.scrollTo(0, 0)
    // fetchCategoriesBySlug();
    fetchCirclesData()
    // fetchBlocksData()
  }, [id])

  const { search } = useLocation()

  let parsed = search.replace('?', '')
  const { circleName, blockName } = queryString.parse(parsed)

  // On page load if we have /:CircleName
  useEffect(() => {
    const getCircle = circles.filter(
      (cur) => cur.name === (circleName ? circleName : '')
    )

    // We are assigning selected circle Id
    setSelectedCircleId(getCircle[0]?.id ? getCircle[0]?.id : '')

    getCircle[0]?.id && fetchBlocksData(`circles[]=${getCircle[0]?.id}`)

    // This value is used for dataList value
    setSelectedCircle(circleName ? circleName : '')
  }, [circleName, circles])

  // On page load if we have /:CircleName
  useEffect(() => {
    const getBlock = blocks.filter(
      (cur) => cur.name === (blockName ? blockName : '')
    )

    // We are assigning selected circle Id
    setSelectedBlockId(getBlock[0]?.id ? getBlock[0]?.id : '')

    // This value is used for dataList value
    setSelectedBlock(blockName ? blockName : '')
  }, [blockName, blocks])

  useEffect(() => {
    console.log('Selected', selectedCircle)
    // fetchServicesBySlug(selectedCircleId)
    if (selectedCircle && selectedBlock) {
      fetchCategoriesBySlug(selectedCircleId, selectedBlockId)
      props.history.replace(
        `/category/${props.match.params.id}?circleName=${selectedCircle}&blockName=${selectedBlock}`
      )
    } else if (selectedCircle) {
      fetchCategoriesBySlug(selectedCircleId)
      props.history.replace(
        `/category/${props.match.params.id}?circleName=${selectedCircle}`
      )
    } else if (selectedBlock) {
      fetchCategoriesBySlug(selectedBlockId)
      props.history.replace(
        `/category/${props.match.params.id}?blockName=${selectedBlock}`
      )
    } else {
      fetchCategoriesBySlug()
    }

    // if (selectedCircle) {
    //   props.history.replace(
    //     `/service/${props.match.params.id}?circleName=${selectedCircle}`
    //   )
    // }
  }, [selectedCircleId, selectedBlockId])

  const fetchCategoriesBySlug = async (selectedCircleId) => {
    setLoading(true)
    setOutlets([]);
    try {
      setId(props.match.params.id)
      const response = await getCategoriesBySlug(
        props.match.params.id,
        selectedCircleId ? selectedCircleId : null,
        selectedBlockId ? selectedBlockId : null,
        'topMenu'
      )
      setPage(response)
      console.log('mine-resp', response)
      // setOutlets(
      //   response?.published_data?.outlets
      //     ? response.published_data.outlets.filter(
      //         (outlet) =>
      //           outlet.status === 'active' &&
      //           outlet.createdBy.status === 'active'
      //       )
      //     : []
      // )
      if(user){
        if(response?.length>0){
      setOutlets(response.filter((item)=>item.status=='active'))
      setNoData(false)
        }else{
          setOutlets([])
          setNoData(true)
        }
      setLoading(false)
      }else{
        if(response?.length>0){
        setOutlets(response.filter((item)=>item.status=='active'))
        setNoData(false)
        
        }else{
          setOutlets([])
          setNoData(true)
        }
        setLoading(false)
      }
    } catch (err) {
      console.log('errors', err)
    }
  }

  useEffect(()=>{
    if(noData){
      setOutlets([])
      setNoData(false)
    }
  },[outlets])

  const fetchCirclesData = async () => {
    try {
      const response = await getCirclesData()
      setCircles(response)
    } catch (err) {
      console.log(err)
    }
  }

  const fetchBlocksData = async (query) => {
    try {
      const response = await getBlocksData(query)
      setBlocks(response)
    } catch (err) {
      console.log(err)
    }
  }

  console.log(outlets, 'pls')

  const refScroller = (data) => {
    if (props.location.pathname === '/home') {
      const section = document.querySelector(`#${data}`)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      props.history.push({
        pathname: '/',
        state: { detail: data },
      })
    }
  }

  function stripHtml(html) {
    var temporalDivElement = document.createElement('div')
    temporalDivElement.innerHTML = html
    let content =
      temporalDivElement.textContent || temporalDivElement.innerText || ''
    return content
  }

  const createMarkup = (data) => {
    return { __html: data }
  }

  const handleSearchChange = (evt) => {
    // const searchResult = page?.published_data?.outlets.filter(
      const searchResult = page?.filter(
      (outlet) =>
        outlet?.title?.toLowerCase().includes(evt.target.value.toLowerCase()) &&
        outlet.status === 'active' &&
        outlet.createdBy.status === 'active'
    )
    setOutlets(searchResult)
  }

  // const onChangeCircle = (e) => {
  //   // Data list <Tag> is not providing the right values we want
  //   const getCircle = circles.filter((cur) => cur.name === e.target.value)

  //   // We are assigning selected circle Id
  //   setSelectedCircleId(getCircle[0]?.id ? getCircle[0]?.id : '')

  //   // This value is used for dataList value
  //   setSelectedCircle(e.target.value)
  //   if (e.target.value === 'All' || e.target.value === '') {
  //     props.history.replace(`/category/${props.match.params.id}`)
  //   }
  // }

  const onChangeCircle = (e) => {
    // // Data list <Tag> is not providing the right values we want

    if ((e === 'All' || e === '') && selectedBlock) {
      props.history.replace(`/category/${props.match.params.id}`)
    } else if ((e === 'All' || e === '') && !selectedBlock) {
      props.history.replace(`/category/${props.match.params.id}`)
      fetchBlocksData()
    } else {
      const getCircle = circles.filter((cur) => cur.id === e)

      // // We are assigning selected circle Id
      // setSelectedCircleId(getCircle[0]?.id ? getCircle[0]?.id : '')

      // This value is used for dataList value
      setSelectedCircleId(e)

      setSelectedBlock(null)

      setSelectedCircle(getCircle[0]?.name)
    }
  }

  const onChangeBlock = (e) => {
    // // Data list <Tag> is not providing the right values we want

    if ((e === 'All' || e === '') && selectedCircle) {
      props.history.replace(
        `/category/${props.match.params.id}?circleName=${selectedCircle}`
      )
    } else if ((e === 'All' || e === '') && !selectedCircle) {
      props.history.replace(`/category/${props.match.params.id}`)
    } else {
      const getBlock = blocks.filter((cur) => cur.id === e)

      // // We are assigning selected circle Id
      // setSelectedCircleId(getBlock[0]?.id ? getBlock[0]?.id : '')

      // This value is used for dataList value
      setSelectedBlockId(e)

      setSelectedBlock(getBlock[0]?.name)
    }
  }

  console.log('outlet data', outlets)

  return (
    <>
      {page && Object.keys(page) ? (
        <div>
          <Header refScroller={refScroller} data={data?.data} />
          {page.published_data && (
            <Helmet>
              <title>{page.published_data.metaTitle}</title>
              <meta name="og:title" content={page.published_data.metaTitle} />
              <meta
                name="twitter:title"
                property="og:title"
                content={page.published_data.metaTitle}
              />
              <meta
                name="twitter:description"
                content={page.published_data.metaDescription}
              />
              <meta
                name="og:description"
                content={page.published_data.metaDescription}
              />
              <meta
                name="description"
                content={page.published_data.metaDescription}
              />
              <meta
                name="keywords"
                content={page.published_data.metaKeywords}
              />
            </Helmet>
          )}
          <div className="waxon_tm_blog_single">
            <div className="hero classic">
              <div
                className="articleImg"
                style={{ backgroundImage: `url(${HeroImg})` }}
              />
            </div>
            <div
              style={{
                float: 'right',
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: 45,
                gap: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              {/* <div style={{ marginRight: 20, marginBottom: 10 }}> */}
              {/* <input
                  list="circles"
                  name="circle"
                  placeholder="Select Circle"
                  value={selectedCircle}
                  onChange={(e) => onChangeCircle(e)}
                  style={{
                    color: 'black',
                    width: '300px',
                    backgroundColor: '#f5f8fc',
                    // float: "right",
                    borderRadius: '50px',
                    border: 'none',
                    padding: 8,
                    paddingLeft: 10,
                  }}
                /> */}

              <div className="custom-select-antd filter-dropdown">
                <label>Circle</label>
                <br />
                <Select
                  showSearch
                  style={{ width: width > 768 ? 300 : '95vw' }}
                  placeholder="Select a Circle"
                  optionFilterProp="children"
                  onChange={onChangeCircle}
                  value={selectedCircleId}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="All">All</Option>
                  {circles?.map((cur) => (
                    <Option value={cur.id}>{cur.name}</Option>
                  ))}
                </Select>
              </div>

              <div className="custom-select-antd filter-dropdown">
                <label>Locality</label>
                <br />
                <Select
                  showSearch
                  style={{ width: width > 768 ? 300 : '95vw' }}
                  placeholder="Select a Locality"
                  optionFilterProp="children"
                  onChange={onChangeBlock}
                  value={selectedBlockId}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="All">All</Option>
                  {blocks?.map((cur) => (
                    <Option value={cur.id}>{cur.name}</Option>
                  ))}
                </Select>
              </div>
              {/* <datalist id="circles">
                  <option data-value="">All</option>
                  {circles?.length > 0 &&
                    circles.map((cur) => <option value={cur.name} />)}
                </datalist> */}
              {/* </div> */}
              <input
                onChange={handleSearchChange}
                // style={{
                //   color: "black",
                //   width: "350px",
                //   backgroundColor: "#f5f8fc",
                //   // float: "right",
                //   borderRadius: "50px",
                // }}
                style={{ marginTop: 34, marginRight: width > 768 ? '15px': '', width: width > 768 ? 300 : '95vw' }}
                className="searchbar-homes filter-dropdown"
                type="text"
                placeholder="Search Outlets"
              />
            </div>
          </div>
          <div className="waxon_tm_news" id="news">
            <div className="container">
              {page.published_data && (
                <div className="waxon_tm_main_title">
                  <div className="title" style={{ paddingTop: '30px' }}>
                    <h3>
                      {page.published_data.title}
                      <span className="bg">Outlets</span>
                    </h3>
                    <div
                      // style={{ paddingTop: "50px" }}
                      dangerouslySetInnerHTML={createMarkup(
                        page?.published_data?.description
                      )}
                    />
                  </div>
                </div>
              )}

              <div className="news_inner">
                <ul>
                  {outlets?.length > 0 && !loading && (
                    outlets.map((outlet, index) => (
                      <>
                        <li
                          className="wow fadeInDown"
                          data-wow-duration="0.8s"
                          data-wow-delay="0.4s"
                          key={index}
                        >
                          <Link
                                    style={{ color: '#fff' }}
                                    to={{
                                      pathname: `/outlets/${outlet.slug}`,
                                      state: id,
                                      search: id,
                                    }}
                                  >
                          <div className="list_inner">
                            <div className="image">
                              {outlet.image ? (
                                <>
                                  <img
                                    src={outlet.image}
                                    alt="main"
                                    style={{ width: '100%', height: '260px' }}
                                  />
                                  <div
                                    className="main"
                                    style={{
                                      backgroundImage: `url(${outlet.image})`,
                                      width: '100%',
                                      height: '260px',
                                    }}
                                  ></div>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={noimage}
                                    alt="blank"
                                    style={{
                                      width: '100%',
                                      height: '260px',
                                      backgroundImage: `url(${noimage})`,
                                    }}
                                  />
                                </>
                              )}
                            </div>
                            <div className="details">
                              <h3 className="title" style={{ fontSize: 12 }}>
                                {page?.published_data?.title}
                              </h3>
                              <h3 className="title">
                                <TextTruncate
                                  line={1}
                                  element="span"
                                  truncateText="…"
                                  text={stripHtml(outlet.title)}
                                />
                              </h3>
                              <div></div>
                              <div>
                                {/* <div>
                                  {stripHtml(outlet.description) + '...'}
                                </div> */}
                                <TextTruncate
                                  line={2}
                                  element="span"
                                  truncateText="…"
                                  text={stripHtml(outlet.description)}
                                  style={{color: "#333333"}}
                                />
                              </div>
                              <div className="mt-1">
                                {/* <p>
                                  <small>
                                    <i
                                      class="fa fa-phone fa-lg"
                                      saria-hidden="true"
                                    />
                                    <b class="mx-2">Call Now</b>
                                    {outlet.call_now}
                                  </small>
                                </p>
                                <p>
                                  <small>
                                    <i
                                      class="fa fa-whatsapp my-float"
                                      saria-hidden="true"
                                    ></i>
                                    <b class="mx-2">Whatsapp</b>
                                    {outlet.whatsapp_no}
                                  </small>
                                </p> */}
                              </div>
                              {/* <div>
                              <Link
                                  style={{
                                    float: "right",
                                    backgroundColor: "#4ab7ff",
                                    padding: "10px",
                                    border: "none",
                                    borderRadius: "10px",
                                    margin: "10px",
                                    color: "#fff",
                                  }}
                                  to={{
                                    pathname: `/outlets/${outlet.slug}`,
                                    state: id,
                                    search: id,
                                  }}
                                >
                                  Read More
                                </Link>
                              </div> */}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginTop: 'auto',
                                }}
                              >
                                <div
                                  className="call-align"
                                  style={{
                                    textAlign: 'center',
                                    backgroundColor: '#4ab7ff',
                                    lineHeight: '45px',
                                    padding: '4px',
                                    border: 'none',
                                    borderRadius: '10px',
                                    color: '#fff',
                                    width: '110px',
                                    height: '50px',
                                  }}
                                >
                                  <a
                                    href={`tel://${outlet.call_now}`}
                                    style={{
                                      color: 'white',
                                      textDecoration: 'none',
                                    }}
                                  >
                                    <i
                                      class="fa fa-phone fa-lg"
                                      saria-hidden="true"
                                    ></i>{' '}
                                    Call Us
                                  </a>
                                </div>

                                {outlet?.whatsapp_no && (
                                  <div
                                    style={{
                                      backgroundColor: '#25D366',
                                      padding: '10px',
                                      border: 'none',
                                      borderRadius: '10px',
                                      cursor: 'pointer',
                                      margin: '10px 2px',
                                      color: '#fff',
                                      textAlign: 'center',
                                      width: '110px',
                                      height: '50px',
                                    }}
                                  >
                                    <a
                                      href={`https://api.whatsapp.com/send?phone=${outlet.whatsapp_no}&amp;text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202.`}
                                      style={{
                                        color: 'white',
                                        textDecoration: 'none',
                                      }}
                                    >
                                      WhatsApp
                                    </a>
                                  </div>
                                )}

                                {/* <div></div> */}

                                <div
                                  style={{
                                    backgroundColor: '#4ab7ff',
                                    padding: '10px',
                                    border: 'none',
                                    borderRadius: '10px',
                                    margin: '10px 2px',
                                    textAlign: 'center',
                                    width: '110px',
                                    height: '50px',
                                  }}
                                >
                                  <Link
                                    style={{ color: '#fff' }}
                                    to={{
                                      pathname: `/outlets/${outlet.slug}`,
                                      state: id,
                                      search: id,
                                    }}
                                  >
                                    More &gt;&gt;
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          </Link>
                        </li>
                      </>
                    ))
                  )}
                    {loading && (
                    <Spin size="large" />
                  )}
                </ul>
                {outlets?.length==0 && !loading && (
                  <div className="row">
                      <div className="col d-flex justify-content-center align-items-center">
                        <h3 style={{ fontWeight: 'bold' }}>No Outlets Found</h3>
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <NotFound />
      )}
    </>
  )
}

export default Category
