import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import {
  getArticlesBySlug,
  getBlocksData,
  getCirclesData,
  homePageData,
} from "../api";
import noimage from "../../img/No-Photo-Available.jpg";
import { Link, useLocation } from "react-router-dom";
import HeroImg from "../../img/article.jpg";
import { Helmet } from "react-helmet";
import { NotFound } from "../404Error";
import { useData } from "../../contexts/Datacontext";
import TextTruncate from "react-text-truncate";
import { Select, Spin } from "antd";
import queryString from "query-string";
import useDeviceWidth from "../device-width/UseDeviceWidth";

const ArticleList = (props) => {
  console.log('props are', props)
  const [page, setPage] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [id, setId] = useState("");
  const { data } = useData();
  const [circles, setCircles] = useState([]);
  const [selectedCircleId, setSelectedCircleId] = useState("");
  const [selectedCircle, setSelectedCircle] = useState("");
  const [loading, setLoading] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [selectedBlockId, setSelectedBlockId] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [noData, setNoData] = useState(false)
  const { search } = useLocation();
  const width = useDeviceWidth();

  const user = localStorage.getItem("user")

  let parsed = search.replace("?", "");
  const { circleName, blockName } = queryString.parse(parsed);

  const { Option } = Select;

  

  const fetchCirclesData = async () => {
    try {
      const response = await getCirclesData();
      setCircles(response);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBlocksData = async (query) => {
    try {
      const response = await getBlocksData(query);
      setBlocks(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // fetchCategoriesBySlug();
    fetchCirclesData();
    // fetchBlocksData()
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchArticlesBySlug();
  }, []);

  useEffect(() => {
    console.log('Selected circle and block', selectedCircleId, selectedBlockId)
    // fetchServicesBySlug(selectedCircleId)
    if (selectedCircle && selectedBlock) {
      console.log('only circle and block')
      fetchArticlesBySlug(selectedCircleId, selectedBlockId)
      props.history.replace(
        `/page/${props.match.params.id}?circleName=${selectedCircle}&blockName=${selectedBlock}`
      )
    } else if (selectedCircle) {
      console.log('only circle', selectedCircleId)
      fetchArticlesBySlug(selectedCircleId)
      props.history.replace(
        `/page/${props.match.params.id}?circleName=${selectedCircle}`
      )
    } else if (selectedBlock) {
      console.log('only block')
      fetchArticlesBySlug(selectedBlockId)
      props.history.replace(
        `/page/${props.match.params.id}?blockName=${selectedBlock}`
      )
    } else {
      console.log('only no params')
      fetchArticlesBySlug()
    }

    // if (selectedCircle) {
    //   props.history.replace(
    //     `/service/${props.match.params.id}?circleName=${selectedCircle}`
    //   )
    // }
  }, [selectedCircleId, selectedBlockId])

  console.log('blogs are', blogs)

  const fetchArticlesBySlug = async (selectedCircleId) => {
    setBlogs([])
    setLoading(true)
    try {
      setId(props.match.params.id);
      console.log('only selected id', selectedCircleId)
      const response = await getArticlesBySlug(
        props.match.params.id,
        selectedCircleId ? selectedCircleId : null,
        selectedBlockId ? selectedBlockId : null,
        'topMenu'
      );
      setPage(response);
      console.log('blogs data', response);
      if(user ){
        if(response?.length>0){
      setBlogs(response.filter((item)=>item.status=='active'));
      setNoData(false)
        }else{
          setBlogs([])
          setNoData(true)
        }
      setLoading(false)
      
      // if(!user && response?.length>0){
      //   setBlogs(response.filter((item)=>item.status=='active'));
      //   setLoading(false)
      // }
      }else{
        if(response?.length>0){
          setNoData(false)
        setBlogs(response.filter((item)=>item.status=='active'));
        }else{
          setBlogs([])
          setNoData(true)
        }
        setLoading(false)
      }
    } catch (err) {
      console.log("errors", err);
    }
  };

  useEffect(()=>{
    if(noData){
      setBlogs([])
      setNoData(false)
    }
  },[blogs])

  const onChangeCircle = (e) => {
    // // Data list <Tag> is not providing the right values we want

    if ((e === "All" || e === "") && selectedBlock) {
      props.history.replace(`/page/${props.match.params.id}`);
    } else if ((e === "All" || e === "") && !selectedBlock) {
      props.history.replace(`/page/${props.match.params.id}`);
      fetchBlocksData();
    } else {
      const getCircle = circles.filter((cur) => cur.id === e);

      // // We are assigning selected circle Id
      // setSelectedCircleId(getCircle[0]?.id ? getCircle[0]?.id : '')

      // This value is used for dataList value
      setSelectedCircleId(e);

      setSelectedBlock(null);

      setSelectedCircle(getCircle[0]?.name);
    }
  };

  console.log('selected circle id', selectedCircleId)

  const onChangeBlock = (e) => {
    // // Data list <Tag> is not providing the right values we want

    if ((e === "All" || e === "") && selectedCircle) {
      props.history.replace(
        `/page/${props.match.params.id}?circleName=${selectedCircle}`
      );
    } else if ((e === "All" || e === "") && !selectedCircle) {
      props.history.replace(`/page/${props.match.params.id}`);
    } else {
      const getBlock = blocks.filter((cur) => cur.id === e);

      // // We are assigning selected circle Id
      // setSelectedCircleId(getBlock[0]?.id ? getBlock[0]?.id : '')

      // This value is used for dataList value
      setSelectedBlockId(e);

      setSelectedBlock(getBlock[0]?.name);
    }
  };

  useEffect(() => {
    const getCircle = circles.filter(
      (cur) => cur.name === (circleName ? circleName : "")
    );

    // We are assigning selected circle Id
    setSelectedCircleId(getCircle[0]?.id ? getCircle[0]?.id : "");

    getCircle[0]?.id && fetchBlocksData(`circles[]=${getCircle[0]?.id}`);

    // This value is used for dataList value
    setSelectedCircle(circleName ? circleName : "");
  }, [circleName, circles]);

  // On page load if we have /:CircleName
  useEffect(() => {
    const getBlock = blocks.filter(
      (cur) => cur.name === (blockName ? blockName : "")
    );

    // We are assigning selected circle Id
    setSelectedBlockId(getBlock[0]?.id ? getBlock[0]?.id : "");

    // This value is used for dataList value
    setSelectedBlock(blockName ? blockName : "");
  }, [blockName, blocks]);

  useEffect(() => {
    handlePageData();
  }, []);

  const handlePageData = async () => {
    const res = await homePageData();
    //const favicon = document.getElementById('favicon')
    //if (favicon) favicon.href = res?.logo?.Logo
  };

  const refScroller = (data) => {
    if (props.location.pathname === "/home") {
      const section = document.querySelector(`#${data}`);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      props.history.push({
        pathname: "/",
        state: { detail: data },
      });
    }
  };

  function stripHtml(html) {
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    let content =
      temporalDivElement.textContent || temporalDivElement.innerText || "";
    return content;
  }
  const createMarkup = (data) => {
    return { __html: data };
  };

  const handleSearchChange = (evt) => {
    const searchResult = page?.filter((blog) =>
      blog?.title?.toLowerCase().includes(evt.target.value)
    );
    setBlogs(searchResult);
  };

  return (
    <>
      {Object.keys(page) ? (
        <div>
          <Header refScroller={refScroller} data={data?.data} />
          {page.publishing_Page && (
            <Helmet>
              <title>{page.publishing_Page.metaTitle}</title>
              <meta name="og:title" content={page.publishing_Page.metaTitle} />
              <meta
                name="twitter:title"
                property="og:title"
                content={page.publishing_Page.metaTitle}
              />
              <meta
                name="twitter:description"
                content={page.publishing_Page.metaDescription}
              />
              <meta
                name="og:description"
                content={page.publishing_Page.metaDescription}
              />
              <meta
                name="description"
                content={page.publishing_Page.metaDescription}
              />
              <meta
                name="keywords"
                content={page.publishing_Page.metaKeywords}
              />
            </Helmet>
          )}
          <div className="waxon_tm_blog_single">
            <div className="hero classic">
              <div
                className="articleImg"
                style={{ backgroundImage: `url(${HeroImg})` }}
              />
            </div>
            <div
              style={{
                float: "right",
                display: "flex",
                flexWrap: "wrap",
                marginBottom: 45,
                gap: "20px",
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <div className="custom-select-antd filter-dropdown">
                <label>Circle</label>
                <br />
                <Select
                  showSearch
                  style={{ width: width > 768 ? 300 : '95vw' }}
                  placeholder="Select a Circle"
                  optionFilterProp="children"
                  onChange={onChangeCircle}
                  value={selectedCircleId}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="All">All</Option>
                  {circles?.map((cur) => (
                    <Option value={cur.id}>{cur.name}</Option>
                  ))}
                </Select>
              </div>

              <div className="custom-select-antd filter-dropdown">
                <label>Locality</label>
                <br />
                <Select
                  showSearch
                  style={{ width: width > 768 ? 300 : '95vw' }}
                  placeholder="Select a Locality"
                  optionFilterProp="children"
                  onChange={onChangeBlock}
                  value={selectedBlockId}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="All">All</Option>
                  {blocks?.map((cur) => (
                    <Option value={cur.id}>{cur.name}</Option>
                  ))}
                </Select>
              </div>

              {/* <input
                  onChange={handleSearchChange}
                  style={{
                    color: "black",
                    width: "400px",
                    backgroundColor: "#f5f8fc",
                    float: "right",
                    borderRadius: "50px",
                  }}
                  type="text"
                  placeholder="Search.."
                /> */}
              <input
                onChange={handleSearchChange}
                // style={{
                //   color: "black",
                //   width: "350px",
                //   backgroundColor: "#f5f8fc",
                //   // float: "right",
                //   borderRadius: "50px",
                // }}
                style={{ marginTop: 34, marginRight: width > 768 ? '15px': '', width: width > 768 ? 300 : '95vw' }}
                className="searchbar-homes filter-dropdown"
                type="text"
                placeholder="Search Features"
              />
            </div>
          </div>
          <div className="waxon_tm_news" id="news">
            <div className="container">
              {page.publishing_Page && (
                <div className="waxon_tm_main_title">
                  <div className="title" style={{ paddingTop: "20px" }}>
                    <h3>
                      {page.publishing_Page.title}
                      <span className="bg">Features</span>
                    </h3>
                    <div
                      style={{ paddingTop: "50px" }}
                      dangerouslySetInnerHTML={createMarkup(
                        page?.publishing_Page?.content
                      )}
                    />
                  </div>
                </div>
              )}

              <div className="news_inner">
                <ul>
                  {blogs?.length > 0 && !loading && (
                    blogs.map((blog, j) => (
                      <>
                        <li
                          className="wow fadeInDown"
                          data-wow-duration="0.8s"
                          data-wow-delay="0.4s"
                          key={j}
                        >
                          <Link
                                  to={{
                                    pathname: `/article/${blog.slug}`,
                                    state: id,
                                    search: id,
                                  }}
                                >
                          <div className="list_inner">
                            <div className="image">
                              {blog.photo ? (
                                <>
                                  <img
                                    src={blog.photo}
                                    alt="main"
                                    style={{ width: "450px", height: "260px" }}
                                  />
                                  <div
                                    className="main"
                                    style={{
                                      backgroundImage: `url(${blog.photo})`,
                                      width: "100%",
                                      height: "260px",
                                    }}
                                  ></div>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={noimage}
                                    alt="blank"
                                    style={{
                                      width: "450px",
                                      height: "260px",
                                      backgroundImage: `url(${noimage})`,
                                    }}
                                  />
                                </>
                              )}
                            </div>
                            <div className="details">
                              <h3 className="title" style={{ fontSize: 12 }}>
                                {page?.publishing_Page?.title}
                              </h3>
                              <h3 className="title">
                                <Link
                                  to={{
                                    pathname: `article/${blog.slug}`,
                                    state: id,
                                    search: id,
                                  }}
                                >
                                  <TextTruncate
                                    line={1}
                                    element="span"
                                    truncateText="…"
                                    text={stripHtml(blog.title)}
                                  />
                                  {/* {.title} */}
                                </Link>
                              </h3>
                              <div></div>
                              <div>
                                <div>
                                  {/* {stripHtml(blog.content) + '...'} */}
                                  <TextTruncate
                                    line={2}
                                    element="span"
                                    truncateText="…"
                                    text={stripHtml(blog.content)}
                                    style={{color: "#333333"}}
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: "auto" }}>
                                <Link
                                  style={{
                                    float: "right",
                                    backgroundColor: "#4ab7ff",
                                    padding: "10px",
                                    border: "none",
                                    borderRadius: "10px",
                                    margin: "10px",
                                    color: "#fff",
                                  }}
                                  to={{
                                    pathname: `/article/${blog.slug}`,
                                    state: id,
                                    search: id,
                                  }}
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                          </Link>
                        </li>
                      </>
                    ))
                  )}
                  
                  {loading && (
                    <Spin size="large" />
                  )}
                </ul>
                {blogs?.length==0 && !loading && (
                    <div className="row">
                      <div className="col d-flex justify-content-center align-items-center">
                        <h3 style={{ fontWeight: "bold" }}>No Feature Found</h3>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};
export default ArticleList;
