import React, { useState } from 'react'
import { homePageData } from './Components/api'
import GoogleMap from './Components/Home/GoogleMap'
import CotactForm from './Components/Home/ContactForm'
import { viewAllPolicy } from './api/policy'
import errorValidator from './utils/errorValidator'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
const Footer = ({ history }) => {
  const [state, setState] = React.useState([])
  const [policyData, setPolicyData] = useState([])
  const [copyrightText, setCopyrightText] = useState('')
  let [contactWhatsAppMessage, setContactWhatsAppMessage] = React.useState('')
  React.useEffect(() => {
    const getAllPolicy = async () => {
      try {
        const resp = await viewAllPolicy()
        console.log(resp.data, 'the-resp')
        const policies = resp.data.data.map((cur) => {
          if (cur.type === 1) {
            return { ...cur, type: 'Policies' }
          } else if (cur.type === 2) {
            return { ...cur, type: 'Terms & Conditions' }
          }
          // else if (cur.type === 3) {
          //   return { ...cur, type: "Return & Refund" };
          // } else if (cur.type === 4) {
          //   setCopyrightText(cur.content);
          //   return { ...cur, type: "Copyright" };
          // }
        })

        let removeCopyright = policies.filter((cur) => cur.type !== 'Copyright')

        setPolicyData(removeCopyright)
      } catch (err) {
        // Additional Error checker
        errorValidator(err.response.data || err.response)
      }
    }

    getAllPolicy()
    handleHomePageData()
  }, [])

  const handleHomePageData = async () => {
    let res = await homePageData()
    //map_url
    // const url = res?.contactUs?.map_url;
    // const regex = new RegExp('@(.*),(.*),');
    // const lon_lat_match = url.match(regex);
    // const lng = +lon_lat_match[1];
    // const lat = +lon_lat_match[2];
    setContactWhatsAppMessage(res?.contactUs?.whatsappMessage)
    const lat = 10.952252794380367
    const lng = 76.24775445417389

    setState({ ...res?.contactUs, lng, lat })
  }

  console.log('contact ', state)
  return (
    <>
      {state && (
        <>
          <div
            className="waxon_tm_contact"
            id="contact"
            style={{ marginTop: 50 }}
          >
            <div className="bg_image"></div>
            {/* <div className="container"> */}
            <div className="contact_inner">
              <div className="waxon_tm_main_title" style={{ marginLeft: '5%' }}>
                <div className="title">
                  <h3>
                    Enquiry/Feedback/Complaint
                    <span className="bg">Contact</span>
                  </h3>
                </div>
              </div>
              <div
                className="contact_container"
                // style={{width:'auto',display:'flex', justifyContent:'left' }}
              >
                <div className="desc">
                  <CotactForm />
                  <div style={{ diplay: 'flex', flexDirection: 'column' }}>
                    <ul>
                      {state.message && (
                        <li>
                          <p>
                            {/* <b className="mr-2">Message:</b> */}
                            <span>{state.message}</span>
                          </p>
                        </li>
                      )}
                      <li>
                        <p>
                          <b className="mr-2">Address:</b>
                          <span>{state.address}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <b className="mr-2">Helpline Number:</b>
                          <span>
                            <a href={`tel://${state.help_Num}`}>
                              {state.help_Num}
                            </a>
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <b className="mr-2">Email:</b>
                          <span>
                            <a href={'mailto:' + state.email}>{state.email}</a>
                          </span>
                        </p>
                      </li>

                      <li
                        className="wow fadeInDown"
                        style={{ listStyle: 'none' }}
                        data-wow-duration="0.8s"
                        data-wow-delay="0.6s"
                      >
                        <div className="social">
                          <ul>
                            <li>
                              <a href={state.fblink}>
                                <span className="first">
                                  <i className="fa fa-facebook"></i>
                                </span>
                                <span className="second">
                                  <i className="fa fa-facebook"></i>
                                </span>
                              </a>
                            </li>

                            <li>
                              <a href={state.instagram}>
                                <span className="first">
                                  <i className="fa fa-instagram"></i>
                                </span>
                                <span className="second">
                                  <i className="fa fa-instagram"></i>
                                </span>
                              </a>
                            </li>

                            <li>
                              <a href={state.twitter}>
                                <span className="first">
                                  <i className="fa fa-twitter"></i>
                                </span>
                                <span className="second">
                                  <i className="fa fa-twitter"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href={state.linkedin}>
                                <span className="first">
                                  <i className="fa fa-linkedin"></i>
                                </span>
                                <span className="second">
                                  <i className="fa fa-linkedin"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href={state.youtube}>
                                <span className="first">
                                  <i className="fa fa-youtube"></i>
                                </span>
                                <span className="second">
                                  <i className="fa fa-youtube"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* </div> */}
                {state.lat && (
                  <div style={{ marginTop: '40px' }} className="map-height">
                    <GoogleMap lat={state.lat} lng={state.lng} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="waxon_tm_copyright">
            <div className="container">
              <div className="copyright_inner">
                <div className="footer-center-text text-center">
                  <div className="wow fadeInDown" data-wow-duration="0.8s">
                    <span>
                      {/* Owned by {state.ownedby} &copy; {state.since}-
                      {new Date().getFullYear()} */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            copyrightText ||
                            `Copyright © ${state.since} - ${new Date().getFullYear()}. All Rights Reserved.`,
                        }}
                      ></div>
                    </span>
                  </div>
                </div>
                <ul>
                  <li className="wow fadeInDown" data-wow-duration="0.8s">
                    <span>
                      Technical Crew{' '}
                      <a href={state.developedby_url}>{state.developedby}</a>
                    </span>
                  </li>

                  <li className="wow fadeInDown" data-wow-duration="0.8s">
                    <span>
                      Creative Crew
                      <a href={state.managedby_url}> {state.managedby}</a>
                    </span>
                  </li>
                </ul>
                <div className="footer-policies">
                  {policyData?.map((cur) => (
                    <Link
                      to={`/policy/${cur.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-policy"
                      // onClick={() => history.push(`/policy/${cur.id}`)}
                    >
                      {cur.type}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="get-touch">
              <a href="tel:919633462465">
                <span className="call-btn">
                  <a href={`tel://${state.help_Num}`}>
                    <i className="fa fa-phone fa-lg" saria-hidden="true"></i>
                  </a>
                </span>
              </a>
              <a
                href={`https://api.whatsapp.com/send?phone=${state.whatsapplink}&text=${contactWhatsAppMessage}`}
                className="whatsappfloat"
                target="_blank"
              >
                <i className="fa fa-whatsapp my-float"></i>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default withRouter(Footer)
